import React from 'react';

function Login() {
  const [loginError, setLoginError] = React.useState<string | null>(null);
  const [loggingIn, setLoggingIn] = React.useState<boolean>(false);
  const [loginDone, setLoginDone] = React.useState<boolean>(false);

  if (loggingIn) {
    return <p>Logging in...</p>;
  }

  if (loginDone) {
    return <p>Login done</p>;
  }

  if (loginError) {
    return <p>Login error {loginError}</p>;
  }

  if (process.env.REACT_APP_DISABLE_LOGIN == 'true') {
    return (
      <div>
        <label htmlFor="users">Select User:</label>
        <br />
        <select name="users" id="users">
          <option value="45196972-4d83-11ee-bfc4-6a65c8d0a1ad;peter@example.com">
            Peter - peter@example.com - 45196972-4d83-11ee-bfc4-6a65c8d0a1ad)
          </option>
          <option value="51492304-8avz-ie45-9d98-1j83ale91l39;sara@example.com">
            Sara - sara@example.com - 51492304-8avz-ie45-9d98-1j83ale91l39
          </option>
        </select>
        <br />
        <button
          onClick={() => {
            const userId = (document.getElementById('users') as HTMLInputElement).value;
            document.cookie = 'a1session=local; path=/';
            localStorage.setItem('local_user_id', userId.split(';')[0]);
            localStorage.setItem('local_user_email', userId.split(';')[1]);
            window.location.replace('/tenantSelection');
          }}
        >
          Login
        </button>
      </div>
    );
  }

  const codeParam = getCodeParam();
  console.log('code', codeParam);
  if (codeParam) {
    console.log('code found');
    console.log('check state');
    const state = getStateParam();
    const origState = window.sessionStorage.getItem('a1state');
    if (state !== origState) {
      console.error('state mismatch');
      setLoginError('state mismatch');
    } else {
      setLoggingIn(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}auth?code=${codeParam}`, {
        method: 'POST',
        credentials: 'include',
      })
        .then((r) => {
          setLoginDone(true);
          if (r.ok) {
            window.location.replace('/tenantSelection');
          } else {
            console.error('error logging in');
            setLoginError('could not get token');
          }
          setLoggingIn(false);
        })
        .catch((e) => {
          console.error(e);
          setLoginError('could not get token');
          setLoggingIn(false);
        });
    }
  } else if (getCookieValue('a1session') === '') {
    console.log('no code and no cookie -> redirect to cognito');
    const array = new Uint32Array(5);
    crypto.getRandomValues(array);
    const state = array.join('');
    const redirect = loginUrl(state);
    window.sessionStorage.setItem('a1state', state);
    window.location.replace(redirect);
  } else {
    console.log('no code found, but cookie -> redirect to dashboard');
    window.location.replace('/tenantSelection');
  }
  return <div />;
}

const loginUrl = (state: string) => {
  const scopes = ['profile', 'openid', 'email'];
  const responseType = 'code';
  const clientId = '5fmo1pt7r5f0898ff0uq15vnnn';
  const redirectUri = process.env.REACT_APP_REDIRECT;
  const loginEndpoint = 'https://api-one-dev.auth.eu-west-1.amazoncognito.com/login';
  return `${loginEndpoint}?scope=${scopes.join(
    '+',
  )}&response_type=${responseType}&state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}`;
};
const getCookieValue = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

const getCodeParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('code');
};

const getStateParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('state');
};

export default Login;
